import React, { useEffect } from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/nav"
import Title from "../components/title"


class Causes extends React.Component {

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO
                title="Your Rights"
                keywords={[`your rights`, `barristers`, `tajwar shelim`, `human rights`, `law`, `uk law`]}
                />


                <div className="hero-splash">
                    <h1 className="hero-title">
                        YOUR RIGHTS
                    </h1>
                </div>

                <div className="container text-white">
                    <div className="row">
                        <div className="col-10 offset-1 mt-5 text-center">
                            <Title>Police Powers</Title>
                            <p className="text-left">
                                <strong>‘Stop and account’</strong> allows police officers to ask questions about who you are, what you are doing in the area, where are you going and what are you carrying. This can happen in any public place and even by non-uniformed officers (they should show a warranty card if that’s the case).

                                You have the legal right to refuse to answer and walk away. You cannot be searched or arrested for failure to answer questions alone.
                                
                                The only time they have the power to stop if they have ‘reasonable grounds’ to think you are carrying a weapon or have reason to believe you are engaging in anti-social behaviour (harassment, alarm & distress.) If the police ask for your name and address, then we must answer them, because not doing so can result in a criminal offence.
                            </p>

                            <p className="text-left">
                                You have the legal right to refuse to answer and walk away. You cannot be searched or arrested for failure to answer questions alone.
                            </p>

                            <p className="text-left">
                                The only time they have the power to stop if they have <strong>‘reasonable grounds’</strong> to think you are carrying a weapon or have reason to believe you are engaging in anti-social behaviour (harassment, alarm & distress.) If the police ask for your name and address, then we must answer them, because not doing so can result in a criminal offence.
                            </p>

                            <p className="text-left">
                                Police generally work in pairs, and since <a href="https://www.theguardian.com/commentisfree/2021/oct/01/sarah-everard-murder-police-powers-stephen-lawrence-judicial-inquiry">Sarah Everard</a> plain-clothed officers should not be deployed on their own. If something seems suspicious, <strong>be cautious</strong>, if there are others around you <strong>make them aware</strong> of the situation, and <strong>further probe</strong> the lone officer with questions:
                            </p>

                            <ul className="ml-5 text-left">
                                <li>Where are your colleagues?</li>

                                <li>Why are you here?</li>
                                
                                <li>Why have you stopped me and why are you talking to me?</li>
                            </ul>

                            <p className="text-left">
                                <strong>‘Stop and search’</strong> allows police officers to search you and your possessions if they have <strong>reasonable grounds</strong> to believe you were involved in a crime or possess a prohibited item. This must be carried out by a police officer, and they can only search your outer clothing.
                            </p>

                            <p className="text-left">
                                You are not required to give your name and address and they should show their identity card. The officer should state their name, police station, what they are searching for, on what ground, and you are allowed a copy of the search record. If you are seen as resisting, they can use <strong>‘reasonable force’</strong> to arrest you.
                            </p>

                            <p className="text-left">
                            Dressing or looking a certain way, or even smelling like cannabis does not meet the legal requirements for a stop (but this has not been legislated at the time of writing).
                            </p>
                        </div>

                        <div className="col-10 offset-1 mt-5 text-center">
                            <Title>Your Rights to Protest</Title>
                            <p className="text-left">
                                The right to protest is enshrined and protected under the European Convention of Human Rights, under <a href="https://www.echr.coe.int/documents/convention_eng.pdf">Article 11 – Freedom of Assembly</a>. This right is not absolute, and can be limited where it is a risk to public health if necessary and proportionate. The recent protests during the height of COVID or an elevated terror threat are examples when this can be the case. <strong>Furthermore, legislation under the current government is putting these rights at risk</strong>.
                            </p>

                            <p className="text-left">
                            Police have an obligation (which can be found in the <a href="https://www.legislation.gov.uk/ukpga/1986/64">Public Order Act</a>) to not restrict this right <strong>unnecessarily</strong>, and must take measures to protect protests of a peaceful nature. The police have the power to impose restrictions when:
                            </p>

                            <ul className="ml-5 text-left">
                                <li>to prevent serious public disorder;</li>

                                <li>there is serious damage to property;</li>
                                
                                <li>there is serious disruption to the life of the community; or</li>
                                
                                <li>to prevent intimidation of others</li>
                            </ul>

                            <p className="text-left">
                                To organise a protest, you must let the relevant police authority know <strong>at least 6 days in advance</strong>. Letting them know the time and date of the protest, your proposed route and main contact of the organiser. The police have the right to limit or change the route and can set conditions on the march (attendance, length & location). <strong>If you are not marching anywhere, you do not need to inform the police.</strong>
                            </p>
                        </div>

                        <div className="col-10 offset-1 mt-5 text-center">
                            <Title>Your Rights to Asylum</Title>
                            <p className="text-left">
                                Everyone has the right to seek and enjoy in other countries asylum from persecution. The <a href="https://www.unhcr.org/uk/1951-refugee-convention.html#:~:text=The%201951%20Refugee%20Convention%20and,of%20States%20to%20protect%20them">1951 UN Convention relating to the Status of Refugees</a> upholds this right
                            </p>

                            <p className="text-left">
                                To claim asylum, an asylum seeker must pass two tests,
                            </p>

                            <ol className="ml-5 text-left">
                                <li>being found <strong>‘credible,’</strong> and</li>
                                <li>having a <strong>‘well-founded fear of persecution’</strong> for reasons under race, religion, nationality, social group or political opinion</li>
                            </ol>

                            <p className="text-left">
                                We’ll go through both of these in turn:<br /><br />

                                <h3>Credibility</h3><br />
                                
                                Your credibility will be assessed through evidence of immigration history and relative<br />
                                
                                country guidance. The Home Office regulates this, and their guidelines are in summary
                            </p>

                            <ul className="ml-5 text-left">
                                <li>fails without reasonable explanation to disclose all important facts;</li>

                                <li>fails without reasonable explanation to apply for asylum on arrival in the UK;</li>
                                
                                <li>uses false evidence to support the application;</li>
                                
                                <li>without reasonable explanation destroys/hides relevant documents and evidence</li>
                            </ul>

                            <p className="text-left">
                            These factors will be considered during your interview. The interview is where the asylum-seeker provides evidence, mainly a witness statement, to support your claim. Applicants should be given the opportunity to explain any inconsistencies and their case

                            should be considered <strong>with all the evidence submitted</strong>. Currently, majority of failed asylum seeker applications are deemed <strong>‘not credible’</strong>, where the Home Office do not believe the account.
                            </p><br/>

                            <p className="text-left">
                                <h3>Well-founded fear of persecution</h3><br/>

                                Your fear of persecution is the reason you are fleeing from your country of origin, under one of the above protected characteristics we looked at earlier. Again, the Home Office have some guidance to assess your claim:<br/>

                                The Home Office guidance states:
                            </p>

                            <ul className=" ml-5 text-left">
                                <li><span className="text-primary"><strong>i.</strong></span> Has the Applicant expressed a fear of return to their home country?</li>
                                <li><span className="text-primary"><strong>ii.</strong></span> What is the harm feared?</li>
                                <li><span className="text-primary"><strong>iii.</strong></span> Is the harm related to one or more of the five Convention grounds? (see above)</li>
                                <li><span className="text-primary"><strong>iv.</strong></span> Who are the agents (who is doing it?) of persecution and is effective state protection available?</li>
                                <li><span className="text-primary"><strong>v.</strong></span> Is the fear well-founded?</li>
                                <li><span className="text-primary"><strong>vi.</strong></span> Is the fear well-founded as to the whole of the country of origin? (can you be relocated to another part of the country)</li>
                            </ul>

                            <p className="text-left">
                            The above will be used to assess your claim. It varies case-by-case and your own personal story. A full breakdown of the threshold can be found at pages 14-19 of <a href="https://www.tajwarshelim.co.uk/Taj_Dissertation.pdf">my Practitioner Article</a>. If you are still not sure please feel free to contact me directly. <strong>These rules are also subject to change given new legislation of the current government</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Causes
